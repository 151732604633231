export function FeatureSection() {
  return (
    <div className="py-16 lg:py-32 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
            Aplikacja Coach Mate
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Nowoczesne narzędzie pracy dla trenera
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto"></p>
        </div>
        <div className="mt-20">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M28 9.533c-0.273-0.126-0.593-0.2-0.931-0.2-0.593 0-1.134 0.228-1.538 0.601l0.002-0.001-2.867 2.667v-1.933c0-2.209-1.791-4-4-4v0h-12c-2.209 0-4 1.791-4 4v0 10.667c0 2.209 1.791 4 4 4v0h12c2.209 0 4-1.791 4-4v0-1.933l2.88 2.667c0.407 0.37 0.95 0.597 1.546 0.6h0.001c0.334-0.001 0.65-0.074 0.934-0.206l-0.014 0.006c0.787-0.325 1.331-1.085 1.333-1.973v-8.987c-0.004-0.891-0.554-1.653-1.332-1.968l-0.014-0.005z"></path>
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  200 ćwiczeń z filmami instruktażowymi
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Rozbudowana baza ćwiczeń od ćwiczeń rozgrzewkowych, przez
                  ćwiczenia siłowe, po ćwiczenia szybkości i mocy przydatne dla
                  sportowców. Stworzone przez nas filmy dokładnie demonstrują
                  ćwiczenia zawarte w planie treningowym.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M16 5.333c-0.736 0-1.333 0.597-1.333 1.333v0 20c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-20c0-0.736-0.597-1.333-1.333-1.333v0z"></path>
                    <path d="M25.333 16c-0.736 0-1.333 0.597-1.333 1.333v0 9.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-9.333c0-0.736-0.597-1.333-1.333-1.333v0z"></path>
                    <path d="M6.667 10.667c-0.736 0-1.333 0.597-1.333 1.333v0 14.667c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v0-14.667c0-0.736-0.597-1.333-1.333-1.333v0z"></path>
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Intuicyjna aplikacja na telefon
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Każdy trenujący otrzyma swój plan treningowy za pomocą
                  intuicyjnej aplikacji na telefon. Aplikacja prowadzi przez
                  trening krok po kroku i pozwala śledzić progres.
                </dd>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M12.947 15.053c-0.242-0.242-0.577-0.392-0.947-0.392-0.739 0-1.339 0.599-1.339 1.339 0 0.37 0.15 0.704 0.392 0.947l4 4c0.241 0.239 0.573 0.387 0.939 0.387 0.003 0 0.005 0 0.008-0h-0c0.383-0.013 0.724-0.185 0.959-0.452l0.001-0.001 9.333-10.667c0.184-0.227 0.295-0.518 0.295-0.836 0-0.736-0.597-1.333-1.333-1.333-0.378 0-0.719 0.157-0.961 0.409l-0 0-8.293 9.6z"></path>
                    <path d="M28 14.667c-0.736 0-1.333 0.597-1.333 1.333v0c0 5.891-4.776 10.667-10.667 10.667v0c-5.889-0.003-10.662-4.777-10.662-10.667 0-2.934 1.185-5.592 3.102-7.521l-0.001 0.001c1.918-1.943 4.582-3.147 7.526-3.147 0.012 0 0.024 0 0.036 0h-0.002c0.901 0.006 1.774 0.112 2.613 0.309l-0.079-0.016c0.118 0.038 0.253 0.060 0.394 0.060 0.736 0 1.333-0.597 1.333-1.333 0-0.654-0.471-1.198-1.093-1.312l-0.008-0.001c-0.948-0.233-2.037-0.369-3.157-0.373h-0.003c-7.334 0.039-13.264 5.994-13.264 13.333 0 3.695 1.503 7.038 3.93 9.453l0.001 0.001c2.389 2.39 5.687 3.871 9.332 3.88h0.002c7.364 0 13.333-5.97 13.333-13.333v0c0-0.736-0.597-1.333-1.333-1.333v0z"></path>
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Oszczędzaj czas i energię
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Wiemy, jak czasochłonne i frustrujące potrafi być rozpisywanie
                  planów treningowych przy użyciu plików PDF czy arkuszy Google.
                  Skorzystaj z platformy która od samego początku została
                  zaprojektowana z myślą o trenerach i ich potrzebach.
                </dd>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 23 32"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M22.857 24.982c0 2.607-1.714 4.732-3.804 4.732h-15.25c-2.089 0-3.804-2.125-3.804-4.732 0-4.696 1.161-10.125 5.839-10.125 1.446 1.411 3.411 2.286 5.589 2.286s4.143-0.875 5.589-2.286c4.679 0 5.839 5.429 5.839 10.125zM18.286 9.143c0 3.786-3.071 6.857-6.857 6.857s-6.857-3.071-6.857-6.857 3.071-6.857 6.857-6.857 6.857 3.071 6.857 6.857z"></path>
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Profesjonalny panel trenera
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  W prosty sposób zarządzaj planami trenujących. Dodawaj
                  jednostki treningowe, twórz własne szablony planów i śledź
                  progress trenujących. Nasz panel pozwoli Ci stworzyć wyjątkową
                  bazę, która będzie Twoją wizytówką.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
