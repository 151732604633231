import { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

type Props = {
  fill: "primary" | "secondary" | "highlight";
  children: React.ReactNode;
  size?: "medium" | "large";
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const btnStyles = {
  primary: "text-white bg-indigo-600 hover:bg-indigo-700",
  secondary: "text-indigo-600 bg-white hover:bg-indigo-50",
  highlight:
    "text-white bg-gradient-to-r from-indigo-500 to-indigo-700 hover:from-indigo-500 hover:to-indigo-600 tracking-wide",
};

const sizes = {
  medium: "px-5 py-3 text-base font-medium",
  large: "px-6 py-4 text-lg font-medium",
};

export function Button({ fill, children, size = "medium", ...props }: Props) {
  return (
    <button
      className={`inline-flex items-center justify-center rounded-md ${btnStyles[fill]} ${sizes[size]}`}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}
