type Props = {
  text: string;
  subtext?: string;
  highlightText?: string;
  children?: React.ReactNode;
};

export function HeaderSection({ text, children, highlightText }: Props) {
  return (
    <div className="w-full max-w-7xl text-center mx-auto px-2 flex flex-col items-center">
      <h2 className="block text-indigo-600 text-md font-semibold">
        {highlightText}
      </h2>
      <h1 className="block text-gray-900 text-4xl md:text-6xl font-extrabold py-4">
        {text}
      </h1>
      <h3 className="block text-gray-500 text-md text-center">{children}</h3>
    </div>
  );
}
