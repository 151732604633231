import { CldVideoPlayer } from "next-cloudinary";

export function PromoVideo() {
  return (
    <CldVideoPlayer
      width="100%"
      height="auto"
      quality="auto:best"
      src="coachmate-promo"
      logo={false}
      autoPlay="on-scroll"
      className="shadow-xl xl:rounded-xl"
      colors={{
        accent: "#4f46e5",
        base: "#111827",
        text: "#ffffff",
      }}
    />
  );
}
