import Head from "next/head";
import Image from "next/image";
import { useState } from "react";

import { Button } from "../components/Button";
import { CTA } from "../components/CTA";
import { FeatureSection } from "../components/FeatureSection";
import { HeaderSection } from "../components/HeaderSection";
import { Hero } from "../components/Hero";
import { PromoVideo } from "../components/PromoVideo/PromoVideo";
import { Showcase } from "../components/Showcase";
import { TestimonialsSection } from "../components/TestimonialsSection";

export default function Home() {
  const [mainPanelLoaded, setMainPanelLoaded] = useState(false);

  return (
    <>
      <Head>
        <title>Coach Mate</title>
        <meta
          name="description"
          content="Platforma dla trenerów pozwalająca w prosty i skuteczny sposób tworzyć plany treningowe. Oszczędzaj czas i wznieś współpracę z trenującymi na jeszcze wyższy poziom!"
        />
      </Head>
      <Hero
        text="Tworzenie treningów właśnie stało się"
        textHighlighted="proste"
        subtext="Coach Mate to platforma dla trenerów, która pozwala w prosty i skuteczny sposób tworzyć plany treningowe. Oszczędzaj czas i wznieś współpracę z trenującymi  na jeszcze wyższy poziom!"
        ctaContent={
          <div className="w-full flex">
            <a href={`${process.env.NEXT_PUBLIC_PANEL_URL}/register`}>
              <Button fill="highlight" size="large">
                Wypróbuj za darmo!
              </Button>
            </a>
          </div>
        }
        clip="top"
      >
        <div className="absolute w-full h-full bg-gradient-to-br from-indigo-600 to-indigo-900" />
        <div className="relative pt-6 h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full">
          <div className="relative left-4 top-4 lg:left-44 lg:top-12">
            <div className="absolute transform">
              <div
                className="transition-opacity absolute bg-white transform translate-x-2 -translate-y-2 rounded-lg"
                style={{
                  width: 400 * 1.6,
                  height: 400,
                  opacity: mainPanelLoaded ? 0.7 : 0,
                }}
              />
              <Image
                className="rounded-lg shadow-lg"
                src="/panel-users.png"
                alt="Panel demo"
                width={400 * 1.6}
                height={400}
                onLoadingComplete={() => setMainPanelLoaded(true)}
                priority
              />
              <div className="absolute transform translate-x-72 top-16 shadow-lg">
                <Image
                  className="rounded-lg shadow-xl"
                  src="/panel-workout-create.png"
                  alt="Panel demo"
                  width={400 * 1.32}
                  height={400}
                  priority
                />
              </div>
            </div>
          </div>
          <div className="relative right-0 top-0 transform scale-75 md:scale-100 translate-x-1/4 md:translate-x-1/2 translate-y-12 md:translate-y-24 lg:transform-none lg:top-44 lg:left-0">
            <div className="absolute transform translate-x-16 -translate-y-4 rotate-12">
              <Image
                src="/app-workout.png"
                alt="App demo"
                width={400}
                height={400}
                priority
              />
            </div>
            <div className="absolute transform translate-x-8 -translate-y-2 rotate-6">
              <Image
                src="/app-exercise-list.png"
                alt="App demo"
                width={400}
                height={400}
                priority
              />
            </div>
            <div className="absolute">
              <Image
                src="/app-dashboard.png"
                alt="App demo"
                width={400}
                height={400}
                priority
              />
            </div>
          </div>
        </div>
      </Hero>
      <div className="max-w-7xl m-auto pt-12 md:pt-24">
        <PromoVideo />
      </div>
      <FeatureSection />
      {/* <div className="pb-0">
        <Panel
          title="Treningi piłkarskie? To nasza specjalność!"
          subtext="Na współpracę z Coach Mate decydują się również najlepsze akademie piłkarskie w Polsce. Dzięki naszej aplikacji, każdy zawodnik może otrzymać trening motoryczny i trening techniczny, który wykonuje samodzielnie w formie “pracy domowej”."
          ctaLink="mailto:get@coachmate.app"
          imageSrc="/football.jpg"
        />
      </div>
      <Partners
        title="Współpracują z nami najlepsze akademie piłkarskie w polsce"
        partnerLogos={[
          { src: "/partner-logos/sleza-wroclaw.png", alt: "sleza-wroclaw" },
          { src: "/partner-logos/stal-swidnica.svg", alt: "stal-swidnica" },
          { src: "/partner-logos/konskie-neptun.svg", alt: "konskie-neptun" },
        ]}
      /> */}
      <div className="py-12 md:py-24">
        <HeaderSection highlightText="DLA TRENERA" text="Panel trenera">
          Zarządzaj użytkownikami, rozpisuj treningi i śledź progres. Wszystko w
          jednym miejscu.
        </HeaderSection>
      </div>
      <Showcase
        text="Widok użytkowników"
        subtext="Przeglądaj, dodawaj, usuwaj oraz śledź progres użytkowników. Po ukończeniu treningu od razu podejrzysz dane."
        clip="top"
      >
        <div className="absolute w-full h-full bg-gradient-to-br from-indigo-600 to-indigo-900" />
        <div className="relative pt-8 lg:pt-0 ml-4 lg:ml-32 mt-10 h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full">
          <Image
            className="rounded-md"
            src="/panel-users.png"
            alt="Panel users"
            width={300 * 1.6}
            height={300}
          />
          <div className="absolute top-20 left-40 shadow-lg">
            <Image
              className="rounded-md"
              src="/panel-user.png"
              alt="Panel users"
              width={300 * 1.6}
              height={300}
            />
          </div>
        </div>
      </Showcase>
      <div className="lg:h-16" />
      <Showcase
        text="Szablony treningowe"
        subtext="Stwórz w pełni indywidualny plan treningowy w kilka minut, a następnie zapisz go jako szablon. Rozpisywanie planów nigdy nie było prostsze."
        side="right"
        clip="top"
      >
        <div className="absolute w-full h-full bg-gradient-to-br from-indigo-600 to-indigo-900" />
        <div className="relative lg:absolute pt-8 lg:pt-0 ml-4 lg:ml-0 mt-10 h-56 sm:h-72 md:h-96 lg:h-full right-0 lg:transform lg:-translate-x-1/2">
          <Image
            className="rounded-md"
            src="/panel-workout-create.png"
            alt="Panel users"
            width={400 * 1.32}
            height={400}
          />
        </div>
      </Showcase>
      <div className="lg:h-16" />
      <div className="py-12 md:py-24">
        <HeaderSection
          highlightText="DLA TRENUJĄCEGO"
          text="Intuicyjna aplikacja"
        >
          Sprawdź, jak wygląda aplikacja na telefon Coach Mate.
        </HeaderSection>
      </div>
      <Showcase
        text="Dashboard"
        subtext="Na ekranie głównym znajdziesz wszystkie oczekujące treningi. Dzięki temu będziesz w stanie odpowiednio zaplanować swoje przygotowania, skutecznie realizując cały plan."
        clip="top"
      >
        <div className="absolute w-full h-full bg-gradient-to-br from-indigo-600 to-indigo-900" />
        <div className="relative mt-10 h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full">
          <Image
            src="/app-dashboard.png"
            alt="App demo"
            width={500}
            height={500}
          />
        </div>
      </Showcase>
      <div className="lg:h-16" />
      <Showcase
        text="Lista ćwiczeń"
        subtext="Każdy trening ma swój szczegółowy podgląd. Możesz sprawdzić wszystkie ćwiczenia, które zostały przygotowane w danej jednostce treningowej. Każde ćwiczenie zawiera dodatkowo profesjonalny film instruktażowy."
        side="right"
        clip="top"
      >
        <div className="absolute w-full h-full bg-gradient-to-br from-indigo-600 to-indigo-900" />
        <div className="relative lg:absolute mt-10 h-56 sm:h-72 md:h-96 lg:h-full right-0 lg:transform lg:-translate-x-1/2">
          <Image
            src="/app-exercise-list.png"
            alt="App demo"
            width={500}
            height={500}
          />
        </div>
      </Showcase>
      <div className="lg:h-16" />
      <Showcase
        text="Interaktywny trening"
        subtext="Po rozpoczęciu treningu, możesz w pełni skupić się na pracy do wykonania. Aplikacja prowadzi krok po kroku - pokazuje następne ćwiczenia, zapisuje postępy i odlicza przerwy wypoczynkowe."
        clip="top"
      >
        <div className="absolute w-full h-full bg-gradient-to-br from-indigo-600 to-indigo-900" />
        <div className="relative mt-10 h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full">
          <Image
            src="/app-workout.png"
            alt="App demo"
            width={500}
            height={500}
          />
        </div>
      </Showcase>
      <div className="py-12 md:py-24">
        <HeaderSection text="Co o nas mówią">
          <div>Pracują z nami profesjonaliści. Poznaj ich opinie.</div>
          <div className="text-center mt-6 mb-10">
            <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
            <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
            <span className="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
            <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
            <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
          </div>
        </HeaderSection>
        <div className="px-2">
          <TestimonialsSection />
        </div>
      </div>
      <CTA />
    </>
  );
}
