import { ReactNode } from "react";

type Props = React.PropsWithChildren<{
  text?: string;
  side?: "left" | "right";
  subtext?: string;
  ctaContent?: ReactNode;
  clip?: "top" | "bottom" | "none";
}>;

const leftClasses = {
  showcase: "lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2",
  content: "max-w-7xl mx-auto",
  svg: "right-0 translate-x-1/2",
};
const rightClasses = {
  showcase: "lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2",
  content: "max-w-7xl mx-auto lg:flex lg:justify-end",
  svg: "left-0 -translate-x-1/2",
};

const polygonPoints = {
  top: {
    left: "50,0 100,0 50,100 0,100",
    right: "0,0 50,0 50,100 50,100",
  },
  bottom: {
    left: "0,0 50,0 100,100 50,100",
    right: "50,0 50,100 0,100",
  },
};

export function Showcase({
  text,
  subtext,
  ctaContent,
  children,
  clip = "none",
  side = "left",
}: Props) {
  const classes = side === "left" ? leftClasses : rightClasses;
  return (
    <div className="relative bg-white overflow-hidden">
      <div className={classes.showcase}>{children}</div>
      <div className={classes.content}>
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          {clip !== "none" && (
            <svg
              className={`hidden lg:block absolute inset-y-0 h-full w-48 text-white transform ${classes.svg}`}
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points={polygonPoints[clip][side]} />
            </svg>
          )}
          <div className="relative pt-6 px-4 sm:px-6 lg:px-8" />
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-1xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">
                <span className="block xl:inline">{text}</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {subtext}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                {ctaContent}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
