import { ReactNode } from "react";

type Props = React.PropsWithChildren<{
  text?: string;
  textHighlighted?: string;
  subtext?: string;
  ctaPrimary?: {
    to: string;
    name: string;
  };
  ctaSecondary?: {
    to: string;
    name: string;
  };
  ctaContent?: ReactNode;
  clip?: "top" | "bottom" | "none";
}>;

export function Hero({
  text,
  textHighlighted,
  subtext,
  ctaContent,
  ctaPrimary,
  ctaSecondary,
  children,
  clip = "none",
}: Props) {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        {children}
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          {clip !== "none" && (
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon
                points={
                  clip === "top"
                    ? "50,0 100,0 50,100 0,100"
                    : "0,0 50,0 100,100 50,100"
                }
              />
            </svg>
          )}
          <div className="relative pt-6 px-4 sm:px-6 lg:px-8" />
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span>{text}</span>{" "}
                <span className="text-indigo-600 leading-tight">
                  {textHighlighted}
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {subtext}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                {ctaContent}
                {ctaPrimary && (
                  <div className="rounded-md shadow">
                    <a
                      href={ctaPrimary.to}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      {ctaPrimary.name}
                    </a>
                  </div>
                )}
                {ctaSecondary && (
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a
                      href={ctaSecondary.to}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                    >
                      {ctaSecondary.name}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
