import Image from "next/image";

type Props = {
  name: string;
  description: string;
  imageSrc: string;
  children: React.ReactNode;
};

export function Testimonial({ imageSrc, name, description, children }: Props) {
  return (
    <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
      <div className="w-full flex mb-4 items-center">
        <div className="overflow-hidden rounded-full w-10 h-10 min-w-[40px] bg-gray-50 border border-gray-200">
          <Image src={imageSrc} width={50} height={50} alt={name} />
        </div>
        <div className="flex-grow pl-3">
          <h6 className="font-bold text-sm uppercase text-gray-600">{name}</h6>
          <p className="text-xs">{description}</p>
        </div>
      </div>
      <div className="w-full">
        <p className="text-sm leading-tight">
          <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
            "
          </span>
          {children}
          <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
            "
          </span>
        </p>
      </div>
    </div>
  );
}
