import { Testimonial } from "./Testimonial";

export function TestimonialsSection() {
  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="-mx-3 md:flex items-start">
        <div className="px-3 md:w-1/3">
          <Testimonial
            imageSrc="/testimonials/mateusz-sionkowski.png"
            name="Mateusz Sionkowski"
            description="Trener przygotowania motorycznego Miedź Legnica"
          >
            Coach Mate to dla mnie absolutny game changer. Trening dla
            zawodników w okresie przejściowym, programy prewencyjne,
            indywidualne rozgrzewki - wszystko w prosty i profesjonalny sposób!
          </Testimonial>
          <Testimonial
            imageSrc="/testimonials/jakub-rajtar.png"
            name="Jakub Rajtar"
            description="Trener personalny"
          >
            Jeśli chcesz wyróżnić się na rynku trenerskim, to Coach Mate
            powinien być dla Ciebie idealnym wyborem. Dzięki niemu, mogę tworzyć
            indywidualne programy korekcyjne dla swoich podopiecznych, które
            wykonują w formie zadań domowych i poza naszymi treningami.
          </Testimonial>
        </div>
        <div className="px-3 md:w-1/3">
          <Testimonial
            imageSrc="/testimonials/wojciech-lobodzinski.png"
            name="Wojciech Łobodziński"
            description="I trener MKS Miedź Legnica"
          >
            Aplikacja służy nam do programowania zdalnych treningów zawodników.
            Jest idealnym rozwiązaniem przede wszystkim w okresie roztrenowania.
            Rozpiski pracy indywidualnej są wreszcie dostępne w przystępnej
            formie.
          </Testimonial>
          <Testimonial
            imageSrc="/testimonials/jakub-syga.png"
            name="Jakub Syga"
            description="Trener personalny"
          >
            Dzięki korzystaniu z Coach Mate jestem w stanie współpracować z
            podopiecznymi z całej Polski. To szczególnie ważne w świecie sportu,
            gdzie zawodnicy często zmieniają kluby i miejsce swojego
            zamieszkania.
          </Testimonial>
        </div>
        <div className="hidden md:block px-3 md:w-1/3">
          <Testimonial
            imageSrc="/testimonials/adam-pucek.png"
            name="Adam Pucek"
            description="Trener personalny"
          >
            Ze swoimi podopiecznymi współpracuję w formie online już od dobrych
            kilku lat. Próbowałem różnych narzędzi - gorszych i lepszych, ale
            Coach Mate to zupełnie inny poziom. Dzięki aplikacji oszczędzam dużo
            czasu, a podopieczni chętniej przedłużają pakiety treningowe.
          </Testimonial>
          <Testimonial
            imageSrc="/testimonials/maciej-kuczynski.png"
            name="Maciej Kuczyński"
            description="Fizjoterapeuta reprezentacji Polski U20 w piłce nożnej"
          >
            Dobry fizjoterapeuta powinien pamiętać o tym, że praca manualna to
            dopiero początek, dlatego ogromne znaczenie ma praca własna
            pacjentów. Coach Mate pozwala mi w prosty sposób zaplanować zadania
            dodatkowe dla moich pacjentów.
          </Testimonial>
        </div>
      </div>
    </div>
  );
}
